@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

 .domaines {
  @include media-breakpoint-down(md) {
    background-attachment: fixed;
    background-size: contain;
    background-position-y: 140px;
  }
  @include media-breakpoint-up(md) {
    h5 {
      height: 40px;
    }
 }
}
 .sub-domaines{
    padding-top: 1rem;
    list-style-type: none;
    padding-left: 10px;
    li {
      font-size: 15px;
      display: flex;
      align-items: baseline;
    }
    .icon {
      color: #9DB149;
      padding-right: 10px;
      border-radius: 0;
    }
 }