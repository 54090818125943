@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.banner {
  background-color: #4B4B4B;
  padding-top: 3rem;

  .border-right {
    border-right: 1px;
    border-right-color: #fff;
  }
  @include media-breakpoint-between(sm, md){
    img {
      position: absolute;
      left: 10px;
    }
  }
  .social {
    @include media-breakpoint-down(sm) {
      margin-top: 2rem;
      justify-content: center;
    }
    @include media-breakpoint-between(sm, md) {
      margin-top: 0.5rem;
    }
    i {
      background-color: #9DB149;
      border-radius: 100%;
      width: 50px;
      height: 50px;
      margin: 1rem;
      padding: 1rem;
      text-align: center;

      &::before {
        color: #4B4B4B;
      }
    }
  }

  .presentation {
    padding-left: 4rem;
    padding-top: 3rem;
    color: #fff;
    h2 {
      p {
        padding-left: 2rem;
        width: 50%;
      }
    }
    @include media-breakpoint-down(sm) {
      padding: 1rem 0rem;
      justify-content: center;
      h2 {
        p {
          padding-left: 2rem;
          width: 70%;
        }
      }
    }
    @include media-breakpoint-between(sm, md) {
      padding: 2rem 0rem 0rem;
      h2 {
        p {
          padding-left: 2rem;
          width: 50%;
        }
      }
    }
    i {
      color: #9DB149;
      position: absolute;
      margin-bottom: 1rem;
    }
  }

  .features {
    padding: 2rem 0rem;
    color: #fff;
    @include media-breakpoint-down(sm) {
      padding: 0rem;
      float: none;
    }
    .container {
      margin-top: 0.7rem;
      padding-right:0;
      padding: 2.7rem;
    }
  }
}