.header {
  color: #4B4B4B !important;
}

  .show {
    border-top: 1px solid #9DB149 !important;
    text-align: center;
    margin: 1rem 0rem 0rem;
  }

  .navbar-light {
    & .navbar-toggler {
      &:focus {
        border-color: #9DB149!important;
      }
    }
    & .navbar-nav {
      .nav-link {
        padding: 5px 10px;
        &:focus {
          color: #9DB149;
        }
        &:hover {
          color: #9DB149;
        }
      }

    }
  }