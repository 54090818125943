.services {
  padding-bottom: 4rem;
  .col {
    border: 3px solid #9DB149;
    min-height: 100px;
    margin: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}