@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.contact {
  @include media-breakpoint-down(md) {
   .detail {
     text-align: center;
     iframe {
       margin: 1rem 0rem;
     }
   }
   .form {
     text-align: center;
     margin: 1rem 0rem;
   }
  }
  iframe {
    width: 100%;
    min-height: 30vh;
    @include media-breakpoint-only(sm) {
      min-height: 26vh!important;
    }
  }
  h6 {
    margin-left: 1rem;
    color: slategray;
  }
  .form {
    h5 {
      color: #9DB149;
    }
    input{
      height: 50px;
    }
    .btn {
      background-color: #9DB149;
      color: #fff;
      padding: 1rem;
    }
  }
}