.testimonial {
  color: #fff;
  span {
    color: #9DB149;
  }
  .quote {
    font-size: 3rem;
  }
  img {
    border-radius: 10px;
  }
}